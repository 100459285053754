import * as React from "react"
import styled from 'styled-components'

const Video = ({ video, poster, centered }) => (
    <VideoStyled>
        <video preload="none" className={centered? 'video center' : 'video'} poster={poster} controls autoPlay playsInline loop muted>
            <source src={video} type="video/mp4" />
        </video>
    </VideoStyled>
)

export default Video

const VideoStyled = styled.div`
    height: 50vw;
    max-height: 70vh;

    .video{
        width:100%;
        height:100%;
    }
    .center{
        object-fit:cover;
    }
`