import * as React from "react"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Quote } from '../components/Quote'
import Carousel from '../components/Carousel'
import Video from '../components/Video'

const ShowroomPage = () => {
  const { t } = useTranslation()
  const bgColor = '#eeefed'

  return (
    <div style={{ backgroundColor: bgColor }}>
      <Layout
        header={t("header", { returnObjects: true })}
        footer={t("footer", { returnObjects: true })}
        bgColor={bgColor}
        carousel={t("products", { returnObjects: true })}
        orcamento={t("orcamento", { returnObjects: true })}
      >
        <Seo title="Showroom" />
        <div style={{ width: '80%', margin: 'auto', textAlign: 'center' }}>
          <Video video="/videos/showroom.mp4" poster="images/posters/showroom.png" centered />
        </div>
        <Quote black
          content={t("showroom", { returnObjects: true }).quote}
        />
        <Carousel global
          carousel={t("homeslide", { returnObjects: true })}
        />
      </Layout>
    </div>
  )
}

export default ShowroomPage

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "home", "products", "orcamento", "showroom", "homeslide"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`